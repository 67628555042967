import classNames from "classnames";
import "./RepricingPlanCardBody.scss";

import { FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import {
  BillingStatus,
  FeatureId,
  IBillingItem,
  ISubscriptionInfo,
  UserRole,
} from "src/models";
import { ManageBillingButton } from "src/pages/settings/BillingInfoTab/ManageBillingButton";
import { NextBillingDate } from "src/pages/settings/BillingInfoTab/NextBillingDate";
import { Status } from "src/utility/Status";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { getSalesEmailAddress } from "src/utility/LocalizedEmailAddresses";
import { useAppSelector } from "src/redux/hooks";
import { getIsSupportImpersonationActive } from "src/services/userImpersonation";

type RepricingPlanCardBodyProps = {
  subscriptionInfo: ISubscriptionInfo;
  showManageBillingButton: boolean;
  billingPlan: IBillingItem;
};

export function RepricingPlanCardBody(props: RepricingPlanCardBodyProps) {
  const { subscriptionInfo, showManageBillingButton, billingPlan } = props;

  const navigate = useNavigate();

  const accountInfo = useAppSelector((state) => state.accountInfo);
  const user = useAppSelector((s) => s.user);

  const isOem = user.role === UserRole.OEMAdmin;
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  const flags = useFeatureFlags().getFlags(["repricing_self_service_billing"]);
  const isSelfServiceEnabled = flags.repricing_self_service_billing.enabled;

  const userLimit = subscriptionInfo.limits?.userLimit ?? 0;

  const premiumDataSourceLimit =
    subscriptionInfo.limits?.premiumDriverLimit ?? 0;

  let dataSourceLimit = subscriptionInfo.limits?.dataSourceLimit ?? 0;
  // The data source limit normally includes the premium data source limit.
  // Subtract the premium data source limit since we split them out in the UI.
  if (dataSourceLimit > 0 && premiumDataSourceLimit > 0) {
    dataSourceLimit = dataSourceLimit - premiumDataSourceLimit;
  }

  // Business users need to contact sales to update their billing unless they are in a trial.
  const isUpdatePlanButtonDisabled =
    isSelfServiceEnabled === false ||
    (billingPlan.id === 29 &&
      subscriptionInfo.billingStatus !== BillingStatus.Trial &&
      subscriptionInfo.billingStatus !== BillingStatus.TrialEnding);

  const hideUpdatePlanButton = isOem || isSupportImpersonationActive;

  const featureIds = new Set<FeatureId>(
    subscriptionInfo.limits?.availableFeatureIds ?? [],
  );

  // All plans have virtual datasets, it is not controlled by a FeatureId.
  const planIncludesList: string[] = [];

  if (dataSourceLimit !== -1) {
    planIncludesList.push(
      `${dataSourceLimit} Data Source${dataSourceLimit > 1 ? "s" : ""}`,
    );
  }

  if (premiumDataSourceLimit > 0) {
    planIncludesList.push(
      `${premiumDataSourceLimit} Premium Data Source${
        premiumDataSourceLimit > 1 ? "s" : ""
      }`,
    );
  }

  planIncludesList.push(
    `${userLimit < 0 ? "Unlimited" : userLimit} User Seat${
      userLimit !== 1 ? "s" : ""
    }`,
  );

  planIncludesList.push("Virtual Datasets");

  if (featureIds.has(FeatureId.QueryFederation)) {
    planIncludesList.push("Query Federation");
  }

  if (featureIds.has(FeatureId.DerivedViews)) {
    planIncludesList.push("Derived Views");
  }

  // Any plan that has scheduled queries should have cache jobs and vice versa
  if (
    featureIds.has(FeatureId.ScheduledQueries) ||
    featureIds.has(FeatureId.Caching)
  ) {
    planIncludesList.push("Jobs");
  }

  if (featureIds.has(FeatureId.AIGenerator)) {
    planIncludesList.push("AI SQL Generator");
  }

  if (featureIds.has(FeatureId.SSO)) {
    planIncludesList.push("SSO");
  }

  const salesEmail = getSalesEmailAddress(accountInfo?.country);

  const yearlyOrMonthlyBilling = subscriptionInfo?.isYearly
    ? "Yearly"
    : "Monthly";

  return (
    <CDataCardBody className="repricingPlanCardBody">
      <div className="billingCardHeader">
        <div className="billingCardHeader-title">
          <CDataTypography
            variant="typography-variant-headline-4"
            color="typography-color-dark-grey"
          >
            Billing
          </CDataTypography>
        </div>
        <div className="repricingPlanCardBody-buttonBar">
          {showManageBillingButton && <ManageBillingButton />}
          {!hideUpdatePlanButton && (
            <CDataTooltip
              title={
                <div>
                  Contact <a href={`mailto:${salesEmail}`}>{salesEmail}</a> to
                  make adjustments to your plan.
                </div>
              }
              arrow
              placement="top"
              type={CDataTooltipType.Dark}
              // Do not show the tooltip unless the button is disabled.
              open={isUpdatePlanButtonDisabled ? undefined : false}
            >
              <div>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  disabled={isUpdatePlanButtonDisabled}
                  onClick={() => {
                    navigate("/settings/updatePlan");
                  }}
                >
                  <i className="updateIcon fa-solid fa-pen-to-square" />
                  Update Plan
                </CDataButton>
              </div>
            </CDataTooltip>
          )}
        </div>
      </div>
      <FormGroup className="mb-3">
        <CDataTypography
          variant="typography-variant-body-medium"
          color="typography-color-dark-grey"
          className="mb-2"
        >
          Billing Plan
        </CDataTypography>
        <CDataTypography>
          {billingPlan.name} - {yearlyOrMonthlyBilling}
          <Status className="ms-2" status={subscriptionInfo.billingStatus} />
        </CDataTypography>
      </FormGroup>
      <NextBillingDate subscriptionInfo={subscriptionInfo} />
      <FormGroup>
        <div>
          <CDataTypography
            variant="typography-variant-body-medium"
            color="typography-color-dark-grey"
          >
            Your Plan Details:
          </CDataTypography>
          <div
            className={classNames("featureList", {
              lotsOfItems: planIncludesList.length >= 9,
            })}
          >
            {planIncludesList.map((feature) => {
              return (
                <div key={feature}>
                  <CDataTypography>
                    <CheckmarkIcon />
                    {feature}
                  </CDataTypography>
                </div>
              );
            })}
          </div>
        </div>
      </FormGroup>
    </CDataCardBody>
  );
}

function CheckmarkIcon() {
  return <i className="checkMarkIcon fa fa-solid fa-check" />;
}
