import { useMutation } from "@tanstack/react-query";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { getStripeBillingPortalSession } from "src/pages/settings/BillingInfoTab/api/getStripeBillingPortalSession";
import { SettingTabs } from "src/pages/settings/Settings";

export function ManageBillingButton() {
  const returnUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${SettingTabs.Billing}`;

  const { mutate: navigateToStripePortal } = useMutation({
    mutationKey: ["/billing/createPortalSession"],
    mutationFn: getStripeBillingPortalSession,
    meta: {
      errorMessage:
        "Failed to get billing portal redirect URL due to the following error:",
    },
    onSuccess: (data) => {
      if (data.billingPortalUrl) {
        window.location.href = data.billingPortalUrl;
      }
    },
  });

  return (
    <CDataButton
      buttonType={ButtonType.PrimaryOutline}
      onClick={() => navigateToStripePortal({ returnUrl })}
      data-testid="button-manage-billing"
    >
      <i className="fa fa-credit-card fa-sm align-middle me-2 no-pointer-event" />
      <span className="align-middle">Manage Payment Method</span>
    </CDataButton>
  );
}
