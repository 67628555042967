import * as React from "react";
import classnames from "classnames";
import { ButtonBase, ButtonBaseProps } from "@mui/material";
import "./CDataButton.scss";

export enum ButtonType {
  Primary = "btn-primary",
  Secondary = "btn-secondary",
  Tertiary = "btn-tertiary",
  Success = "btn-success",
  Warning = "btn-warning",
  Danger = "btn-danger",
  Info = "btn-info",
  PrimaryOutline = "btn-outline-primary",
  SecondaryOutline = "btn-outline-secondary",
  SuccessOutline = "btn-outline-success",
  WarningOutline = "btn-outline-warning",
  DangerOutline = "btn-outline-danger",
  InfoOutline = "btn-outline-info",
  Borderless = "btn-borderless",
  NotificationBar = "btn-notification-bar",
  OutlineTransparent = "btn-outline-transparent",
}

export interface CDataButtonProps extends Omit<ButtonBaseProps, "ref"> {
  buttonType: ButtonType;
  buttonRef?: React.Ref<HTMLButtonElement>;
}

export function CDataButton(props: CDataButtonProps) {
  const { buttonType, buttonRef, ...buttonProps } = props;
  const classNames = classnames([
    "btn",
    buttonType,
    "cdata-button",
    props.className,
  ]);

  return (
    <ButtonBase {...buttonProps} className={classNames} ref={buttonRef}>
      {props.children}
    </ButtonBase>
  );
}
