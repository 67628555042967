import { IBillingItem } from "src/models";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";

export const Repricing2025BillingPlanIds = new Set([
  BillingPlanIds.Standard2025,
  BillingPlanIds.Growth2025,
  BillingPlanIds.Business2025,
]);

export function is2025RepricingBillingPlan(
  billingPlan: IBillingItem | null | undefined,
): boolean {
  if (billingPlan == null) return false;

  return Repricing2025BillingPlanIds.has(billingPlan.id);
}
