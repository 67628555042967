import { isFeatureAllowed } from "src/utility/SubscriptionAddonsFactory";
import { FeatureId, UserRole } from "src/models";
import { useAppSelector } from "src/redux/hooks";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useContext } from "react";
import { AuthorizeContext } from "../guards/UserAuthenticationGuard";
import { SidebarOption } from "./SidebarEnums";

export const useIsPathDisplayed = () => {
  const subscription = useAppSelector((state) => state.subscription);
  const userAccount = useContext(AuthorizeContext);
  const routeFlags = useFeatureFlags().getFlags(["odata_client"]);

  const isCachingEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );

  const isScheduledQueriesEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.ScheduledQueries,
  );

  const isOnlySpreadsheetClient = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.ConnectForSpreadsheets,
  );

  const isODataVisible =
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
      .isOdataVisible === true;

  const canImpersonateAsSupport =
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
      .canImpersonateAsSupport === true;

  const getSidebarOptionsByRole = (): SidebarOption[] => {
    const {
      Dashboard,
      Sources,
      Consumers,
      Workspaces,
      Explorer,
      Jobs,
      Users,
      OData,
      Logs,
      Api,
      SupportImpersonation,
    } = SidebarOption;

    switch (userAccount.role) {
      case UserRole.Admin:
        return [
          Dashboard,
          Sources,
          Consumers,
          Workspaces,
          Explorer,
          Jobs,
          Users,
          OData,
          Logs,
          Api,
          SupportImpersonation,
        ];
      case UserRole.Query:
        return [Sources, Consumers, Explorer, Workspaces, Logs, Api];
      case UserRole.OEMAdmin:
        return [Dashboard, Sources, Logs, Api];
      case UserRole.ServiceUser:
        return [Dashboard, Sources, Logs, Api];
      default:
        return [];
    }
  };

  const sidebarOptionsByRole = getSidebarOptionsByRole();

  const getIsPathDisplayed = (path: SidebarOption): boolean => {
    switch (path) {
      case SidebarOption.Dashboard: {
        return sidebarOptionsByRole.includes(SidebarOption.Dashboard);
      }
      case SidebarOption.Sources: {
        return sidebarOptionsByRole.includes(SidebarOption.Sources);
      }
      case SidebarOption.Consumers: {
        return sidebarOptionsByRole.includes(SidebarOption.Consumers);
      }
      case SidebarOption.Workspaces: {
        return (
          sidebarOptionsByRole.includes(SidebarOption.Workspaces) &&
          !isOnlySpreadsheetClient
        );
      }
      case SidebarOption.Explorer: {
        return (
          sidebarOptionsByRole.includes(SidebarOption.Explorer) &&
          !isOnlySpreadsheetClient
        );
      }
      case SidebarOption.Jobs: {
        return (
          sidebarOptionsByRole.includes(SidebarOption.Jobs) &&
          (isCachingEnabled || isScheduledQueriesEnabled) &&
          !isOnlySpreadsheetClient
        );
      }
      case SidebarOption.Users: {
        return sidebarOptionsByRole.includes(SidebarOption.Users);
      }
      case SidebarOption.OData: {
        return (
          sidebarOptionsByRole.includes(SidebarOption.OData) &&
          Boolean(routeFlags.odata_client.enabled) &&
          Boolean(isODataVisible)
        );
      }
      case SidebarOption.Logs: {
        return sidebarOptionsByRole.includes(SidebarOption.Logs);
      }
      case SidebarOption.Api: {
        return sidebarOptionsByRole.includes(SidebarOption.Api);
      }
      case SidebarOption.SupportImpersonation: {
        return (
          sidebarOptionsByRole.includes(SidebarOption.Api) &&
          Boolean(canImpersonateAsSupport) &&
          !isOnlySpreadsheetClient
        );
      }
      default: {
        return true;
      }
    }
  };

  return {
    getIsPathDisplayed,
  };
};
