import { MouseEventHandler } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
import { useIsPathDisplayed } from "./useIsPathDisplayed";
import { SidebarOption } from "./SidebarEnums";
import classnames from "classnames";
import "./SidebarItem.scss";

interface ISidebarItem {
  name: SidebarOption;
  icon: JSX.Element;
  to: string;
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  isNew: boolean;
  testId: string | undefined;
}

export const SidebarItem = ({
  name,
  icon: Icon,
  to,
  isOpen,
  onClick,
  isNew,
  testId,
}: ISidebarItem) => {
  const location = useLocation();
  const displayedPaths = useIsPathDisplayed();

  if (!displayedPaths.getIsPathDisplayed(name)) return undefined;

  const getIsActive = (path: any) => {
    if (
      location.pathname.toLowerCase() === "/apiconnector" &&
      path === "/connections"
    )
      return "active";

    return location.pathname === path ||
      (path !== "/" && location.pathname.startsWith(path))
      ? "active"
      : "";
  };

  return (
    <div className={"sidebar-item"} onClick={onClick}>
      <div className={classnames("active-indicator", getIsActive(to))} />
      {to.includes("http") ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={`sidebar-link ${!isOpen ? "collapsed" : ""}`}
          data-testid={testId}
        >
          <div className="sidebar-link-icon-container">
            {Icon ? Icon : null}
          </div>
          <div className="sidebar-link-spacer" />
          <div className="sidebar-link-name align-middle">
            <span className="sidebar-link-name-outer">{name}</span>
          </div>
        </a>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) =>
            "sidebar-link" +
            (!isOpen ? " collapsed" : "") +
            (isActive ? " active" : "")
          }
          data-testid={testId}
        >
          <div className="sidebar-link-icon-container">
            {Icon ? Icon : null}
          </div>
          <div className="sidebar-link-spacer" />
          <div className="sidebar-link-name align-middle">
            <span className="sidebar-link-name-outer">{name}</span>
          </div>

          {isNew ? (
            <Badge bg="secondary" className="datasets-new-badge">
              New
            </Badge>
          ) : null}
        </NavLink>
      )}
    </div>
  );
};
