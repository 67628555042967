import { useFlagsmith, useFlags } from "flagsmith/react";
import { IFlagsmithFeature } from "flagsmith/types";

export function useFeatureFlags() {
  const flagsmith = useFlagsmith();

  // TODO: CLOUD-7495 - Merge in the GET flags functionality from useFlags to this hook and pass them through

  const setIdentity = (
    userId: string,
    email: string | null | undefined,
    accountId: string | null | undefined,
  ) => {
    if (!window.VITE_CONFIG.VITE_USER_FLAGS_ENABLED) return;

    if (userId && email) {
      flagsmith.identify(userId);
      flagsmith.setTrait("email", email ?? null);
      flagsmith.setTrait("accountId", accountId ?? null);
    }
  };

  const setTrait = (key: string, value: string | null | undefined) => {
    if (window.VITE_CONFIG.VITE_USER_FLAGS_ENABLED) return;

    if (key && value) {
      flagsmith.setTrait(key, value);
    }
  };

  const useCDataFlags = (keys: string[]): Record<string, IFlagsmithFeature> => {
    return useFlags(keys);
  };

  return {
    setIdentity: setIdentity,
    setTrait: setTrait,
    getFlags: useCDataFlags,
  };
}
