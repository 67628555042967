import classNames from "classnames";
import "./CDataRow.scss";

interface CDataRowProps extends React.HTMLAttributes<HTMLDivElement> {
  noGutters?: boolean;
}

export function CDataRow(props: CDataRowProps) {
  const { noGutters, className, ...remainingProps } = props;

  const classes = classNames("cdata-row", className, {
    "no-gutters": noGutters,
  });

  return (
    <div {...remainingProps} className={classes}>
      {props.children}
    </div>
  );
}
