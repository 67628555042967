import { useContext } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  toggleSidebar,
  showSidebar,
  hideSidebar,
} from "../../redux/actions/sidebarActions";
import { AuthorizeContext } from "../guards/UserAuthenticationGuard";
import classNames from "classnames";
import { UserRole } from "src/models";
import ODataIcon from "../../assets/img/icons/ODataRoute.svg?react";
import { SidebarItem } from "./SidebarItem";
import { SidebarCategory } from "./SidebarCategory";
import { SidebarCategories, SidebarOption } from "./SidebarEnums";
import "./Sidebar.scss";

const Sidebar = (props: any) => {
  const sidebar = props.sidebar;
  const userAccount = useContext(AuthorizeContext);

  const keepExpandedOrCollapsed = () => {
    if (props.sidebar.keepExpanded) {
      props.showSidebar();
    } else {
      props.hideSidebar();
    }
  };

  const areCategoriesHidden =
    !sidebar.isOpen ||
    userAccount.role === UserRole.OEMAdmin ||
    userAccount.role === UserRole.ServiceUser;

  return (
    <nav
      className={classNames("sidebar", {
        toggled: !sidebar.isOpen,
        "sidebar-sticky": sidebar.isSticky,
      })}
    >
      <div className="sidebar-content">
        <PerfectScrollbar className="sidebar-container">
          <div className="sidebar-nav">
            <SidebarCategory
              name={SidebarCategories.Overview}
              hidden={
                areCategoriesHidden || userAccount.role === UserRole.Query
              }
            />
            <SidebarItem
              name={SidebarOption.Dashboard}
              to="/"
              icon={<i className="fa-light fa-chart-tree-map fa-lg"></i>}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarCategory
              name={SidebarCategories.Data}
              hidden={areCategoriesHidden}
            />
            <SidebarItem
              name={SidebarOption.Sources}
              to="/connections"
              icon={<i className="fa-regular fa-database fa-lg" />}
              testId={"connections-sidebar-link"}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.Consumers}
              to={userAccount.role === UserRole.Admin ? "/clients" : "/"}
              icon={<i className="fa-regular fa-chart-column fa-lg"></i>}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.Workspaces}
              to="/datasets"
              icon={<i className="fa-regular fa-folder fa-lg" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.Explorer}
              to="/data-explorer"
              icon={<i className="fa-regular fa-magnifying-glass fa-lg" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.Jobs}
              to="/jobs"
              icon={<i className="fa-regular fa-clock fa-lg"></i>}
              testId={""}
              isNew={true}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.OData}
              to="/odata"
              icon={
                <ODataIcon
                  className="fa-regular fa-lg"
                  aria-description="OData logo"
                />
              }
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarCategory
              name={SidebarCategories.Manage}
              hidden={areCategoriesHidden}
            />
            <SidebarItem
              name={SidebarOption.Users}
              to="/user"
              icon={<i className="fa-regular fa-user fa-lg" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.Logs}
              to="/logs"
              icon={<i className="fa-regular fa-file-lines fa-lg" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarCategory
              name={SidebarCategories.Documentation}
              hidden={areCategoriesHidden}
            />
            <SidebarItem
              name={SidebarOption.Api}
              to="https://cloud.cdata.com/docs/API.html"
              icon={<i className="fa-regular fa-code fa-lg" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
            <SidebarItem
              name={SidebarOption.SupportImpersonation}
              to="/impersonation"
              icon={<i className="fa-regular fa-file-lines fa-user-headset" />}
              testId={""}
              isNew={false}
              isOpen={sidebar.isOpen}
              onClick={() => keepExpandedOrCollapsed()}
            />
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    showSidebar: () => dispatch(showSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
  };
};

export default connect(
  (store: any) => ({
    sidebar: store.sidebar,
  }),
  mapDispatchToProps,
)(Sidebar);
