import classNames from "classnames";
import "./CDataCardBody.scss";

export function CDataCardBody(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className, ...remainingProps } = props;

  return (
    <div
      {...remainingProps}
      className={classNames("cdata-card-body", className)}
    >
      {props.children}
    </div>
  );
}
