import { cdataFetch } from "src/api/cdataFetch";
import { IBillingPortalSessionResponse } from "src/models";

export type IGetStripeBillingPortalSessionRequest = {
  returnUrl: string;
};

export function getStripeBillingPortalSession(
  params: IGetStripeBillingPortalSessionRequest,
) {
  return cdataFetch<IBillingPortalSessionResponse>({
    method: "POST",
    url: "/billing/createPortalSession",
    body: params.returnUrl,
  });
}
