import classnames from "classnames";
import "./CDataTypography.scss";

export type TypographyVariant =
  | "typography-variant-jumbo"
  | "typography-variant-headline-1"
  | "typography-variant-headline-2"
  | "typography-variant-headline-3"
  | "typography-variant-headline-4"
  | "typography-variant-headline-5"
  | "typography-variant-card-title"
  | "typography-variant-card-title-bold"
  | "typography-variant-body-bold"
  | "typography-variant-body-medium"
  | "typography-variant-body-medium-italic"
  | "typography-variant-body-regular"
  | "typography-variant-body-regular-italic"
  | "typography-variant-tooltip-medium"
  | "typography-variant-tooltip"
  | "typography-variant-caption"
  | "typography-variant-helper-text"
  | "typography-variant-underline";

export type TypographyColor =
  | "typography-color-text-regular"
  | "typography-color-text-dark"
  | "typography-color-text-muted"
  | "typography-color-dark-grey"
  | "typography-color-medium-grey"
  | "typography-color-light-grey"
  | "typography-color-disabled"
  | "typography-color-gray-065"
  | "typography-color-white"
  | "typography-color-danger"
  | "typography-color-danger-new"
  | "typography-color-success"
  | "typography-color-burnt-orange"
  | "typography-color-dark-blue"
  | "typography-color-sidebar-gray";

interface ICDataTypographyProps {
  variant?: TypographyVariant;
  color?: TypographyColor;
  className?: string;
  children: React.ReactNode;
}

export function CDataTypography(props: ICDataTypographyProps) {
  return (
    <div
      className={classnames(
        "cdata-typography",
        props.variant,
        props.color,
        { "typography-variant-body-regular": !props.variant },
        { "typography-color-text-regular": !props.color },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}
