import { CDataTypography } from "../text/CDataTypography";
import classnames from "classnames";
import { SidebarCategories } from "./SidebarEnums";
import "./SidebarCategory.scss";

interface SidebarCategoryProps {
  name: SidebarCategories;
  hidden?: boolean;
}

export const SidebarCategory = (props: SidebarCategoryProps) => {
  const { name, hidden } = props;

  const classname = classnames("sidebar-category", { "not-displayed": hidden });

  return (
    <div className={classname}>
      <CDataTypography
        variant="typography-variant-caption"
        color="typography-color-light-grey"
      >
        {name}
      </CDataTypography>
    </div>
  );
};
