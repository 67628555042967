import React from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  toggleSidebar,
  showSidebar,
  hideSidebar,
} from "../redux/actions/sidebarActions";
import CloudLogo from "../assets/img/icons/CloudLogo.svg?react";
import { useAuthentication } from "../hooks/useAuthentication";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import OEMServiceUserHeader from "./OEMServiceUserHeader";
import {
  getIsSupportImpersonationActive,
  setIsSupportImpersonationActive,
  getAdminUserEmail,
} from "../services/userImpersonation";
import { RequestType } from "./withAPI";
import { ToastrError } from "../services/toastrService";
import { useAPI } from "./useAPI";
import { IUserImpersonationRequest } from "../models/Users/IUserImpersonationRequest";
import { useAppSelector } from "../redux/hooks";
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import Avatar from "@mui/material/Avatar";
import { CDataCol } from "./layout/CDataCol";
import { CDataRow } from "./layout/CDataRow";
import Box from "@mui/material/Box";
import { SettingTabs } from "../pages/settings/Settings";
import { TextWithOverflowTooltip } from "./text/TextWithOverflowTooltip";
import { UserRole } from "../models/Users/UserRole";

interface INavbarComponentProps {
  toggleSidebar: () => void;
  showSidebar: () => void;
  hideSidebar: () => void;
  sidebar: any;
}

const NavbarComponent: React.FC<INavbarComponentProps> = (props) => {
  const auth = useAuthentication();
  const user = useAppSelector((state) => state.user);
  let userInitials = "";
  if (user.firstName) {
    userInitials += user.firstName[0];
  }
  if (user.lastName) {
    userInitials += user.lastName[0];
  }

  const isSupportImpersonationActive = getIsSupportImpersonationActive();
  const flags = useFeatureFlags().getFlags(["chameleon_search"]);

  const api = useAPI();
  const navigate = useNavigate();

  async function clearUserImpersonationId() {
    if (!isSupportImpersonationActive) {
      return;
    }

    setIsSupportImpersonationActive(false);

    const data: IUserImpersonationRequest = {
      impersonatedUserId: undefined,
    };

    try {
      const { status } = await api.callAPI(
        RequestType.Put,
        `/users/self/impersonate`,
        "Failed to reset User Impersonation Id.",
        data,
        undefined,
        undefined,
        true,
      );

      if (status !== 200) {
        ToastrError(
          "Error updating user impersonation id",
          "Failed to reset user impersonation id.",
        );
      }
    } catch {
      ToastrError(
        "Error updating user impersonation id",
        "Failed to reset user impersonation id.",
      );
    }
  }

  const isOemSub = user?.role === UserRole.ServiceUser;

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle"
        hidden={user?.role === UserRole.OEMAdmin}
        onClick={() => {
          if (props.sidebar.isOpen === props.sidebar.keepExpanded)
            props.toggleSidebar();
          else {
            props.sidebar.isOpen ? props.hideSidebar() : props.showSidebar();
          }
        }}
      >
        <i className="fa fa-regular fa-bars fa-2x align-self-center" />
      </span>
      <a className="logo-expanded" href="/">
        <CloudLogo
          className="cdata-logo"
          aria-description="CData Connect Cloud logo"
        />
        <hr />
      </a>

      {isSupportImpersonationActive ? (
        <Badge className="ms-2" color="quaternary">
          <strong>Support Impersonation</strong>
        </Badge>
      ) : null}

      {isOemSub ? <OEMServiceUserHeader /> : null}

      <Collapse navbar>
        <Nav className="ms-auto" navbar>
          {flags.chameleon_search.enabled ? (
            <span className="chameleon-search nav-icon-container">
              <i className="fa fa-solid fa-search"></i>
            </span>
          ) : null}

          <a
            id="documentationLink"
            href="/docs/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-icon-container"
            aria-label="docs"
          >
            <i className="fa fa-solid fa-circle-question nav-icon"></i>
          </a>
          <UncontrolledTooltip
            placement="bottom"
            target="documentationLink"
            trigger="hover"
          >
            Documentation
          </UncontrolledTooltip>
          <NavLink
            id="settingsLink"
            to="/settings"
            className="nav-icon-container"
          >
            <i className="fa fa-solid fa-gear nav-icon"></i>
          </NavLink>
          <UncontrolledTooltip
            placement="bottom"
            target="settingsLink"
            trigger="hover"
          >
            Settings
          </UncontrolledTooltip>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret data-testid="avatar-icon">
              <Avatar>{userInitials}</Avatar>
            </DropdownToggle>
            <DropdownMenu end className="user-profile-dropdown">
              <Box className="avatar-container">
                <CDataRow style={{ alignItems: "center" }}>
                  <CDataCol className="user-initials">
                    <Avatar>{userInitials}</Avatar>
                  </CDataCol>
                  <CDataCol className="name-email-container">
                    <TextWithOverflowTooltip
                      variant="typography-variant-body-medium"
                      fullText={user.firstName + " " + user.lastName}
                    />
                    <TextWithOverflowTooltip
                      variant="typography-variant-tooltip-medium"
                      color="typography-color-text-muted"
                      className="email"
                      data-testid="user-email-test-id"
                      fullText={
                        (isOemSub ? getAdminUserEmail() : user?.email) ?? ""
                      }
                    />
                  </CDataCol>
                  <CDataCol
                    className="edit-icon"
                    onClick={() =>
                      navigate(
                        "/settings?defaultTab=" + SettingTabs.UserProfile,
                      )
                    }
                  >
                    <i className="fa-regular fa-pen-to-square" />
                  </CDataCol>
                </CDataRow>
              </Box>
              <Box pt={1} className="menu-option-items">
                <DropdownItem tag="a" style={{ height: "32px" }}>
                  <i className="fa-regular fa-comments fa-lg align-middle me-2"></i>
                  CData Community
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  onClick={async () => {
                    await clearUserImpersonationId();
                    auth.Logout();
                  }}
                >
                  <i className="fa-regular fa-right-from-bracket fa-lg align-middle me-2"></i>
                  Sign out
                </DropdownItem>
              </Box>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
    showSidebar: () => dispatch(showSidebar()),
  };
};

// TODO: Figure out if there's a specific type this needs to be for redux
export default connect(
  (store: any) => ({
    sidebar: store.sidebar,
  }),
  mapDispatchToProps,
)(NavbarComponent);
