import { Auth0Provider } from "@auth0/auth0-react";
import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../hooks/useAuthentication";

type ICDataApiProvider = {
  children: ReactNode;
};

export function CDataAuth0Provider(props: ICDataApiProvider) {
  const { children } = props;
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    const currentUrl = new URL(window.location.href);
    const isSessionLogin = currentUrl.searchParams.get("isSessionLogin");
    if (isSessionLogin) {
      sessionStorage.setItem("isSessionLogin", isSessionLogin);
    }
    currentUrl.searchParams.delete("isSessionLogin");
    navigate((appState && appState.returnTo) || currentUrl.pathname);
  };

  return (
    <Auth0Provider
      domain={window.VITE_CONFIG.VITE_AUTH_DOMAIN}
      clientId={window.VITE_CONFIG.VITE_AUTH_CLIENTID}
      redirectUri={`${window.location.origin}?isSessionLogin=true`}
      useRefreshTokens={true}
      audience={window.VITE_CONFIG.VITE_AUTH_AUDIENCE}
      scope={"openid profile email offline_access"}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <InternalApiProvider>{children}</InternalApiProvider>
    </Auth0Provider>
  );
}

function InternalApiProvider(props: ICDataApiProvider) {
  const { children } = props;

  const { GetAccessTokenSilently } = useAuthentication();

  useEffect(() => {
    // This is a hack to let us use GetAccessTokenSilently inside fetch calls without having to
    // make a custom hook and call it everywhere.
    window.GetAccessTokenSilently = GetAccessTokenSilently;
  }, [GetAccessTokenSilently]);

  return <>{children}</>;
}
