import React, { forwardRef, useImperativeHandle, useState } from "react";
import { logoutUser } from "../routes/AuthorizeContext";
import Edge from "../assets/img/edge.svg?react";
import Chrome from "../assets/img/chrome.svg?react";
import Safari from "../assets/img/safari.svg?react";
import Firefox from "../assets/img/firefox.svg?react";
import classNames from "classnames";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import "./CDataModal.scss";

export interface IModalProps {
  close?: () => void;
  displayed: boolean;
  title: string;
  body: JSX.Element;
  primaryButton?: JSX.Element | null;
  secondaryButton?: JSX.Element | null;
  modalSize?: string;
  displayToggleCloseButton?: boolean;
  removeBorders?: boolean;
  spacedFooter?: boolean;
  className?: string;
  removeYPaddingOnBody?: boolean;
}

export interface IModalFunctionFromModal {
  showError: (text: string, error: any) => void;
  showTimeout: () => void;
  /**
   * @deprecated Please look into using `<CDataModalV2 />` directly as a component instead of the modal context.
   *             The context has issues with props not updating since the JSX is stored in a `useState` variable.
   */
  setModal: (modal: IModalProps) => void;
  toggleModal: () => void;
  showUnsupportedBrowser: () => void;
}

export const CDataModal = forwardRef(
  (props: IModalProps, ref: React.Ref<IModalFunctionFromModal>) => {
    const [state, setState] = useState(props);

    const getComponentName = () => {
      return "components-CDataModal";
    };

    const showErrorModal = (text: string, error: any) => {
      const modal = {
        title: "Error",
        body: (
          <div>
            {text}
            <br />
            <b>{error.code}</b>: {error.message}
          </div>
        ),
        secondaryButton: (
          <Button color="primary" onClick={() => handleClose()}>
            Close
          </Button>
        ),
        primaryButton: <React.Fragment />,
        displayToggleCloseButton: true,
        displayed: true,
        modalSize: "lg",
      } as IModalProps;
      setState(modal);
    };

    const resetModal = () => {
      const modal = {
        displayed: false,
        title: "",
        body: <React.Fragment />,
        primaryButton: <React.Fragment />,
        secondaryButton: <React.Fragment />,
        displayToggleCloseButton: true,
        close: () => {
          return;
        },
        modalSize: "lg",
        removeBorders: false,
        className: "",
      } as IModalProps;
      setState(modal);
    };

    useImperativeHandle(ref, () => ({
      showError: (text: string, error: any) => {
        showErrorModal(text, error);
      },
      showTimeout: () => {
        showTimeoutModal();
      },
      setModal: (modal: IModalProps) => {
        setState(modal);
      },
      toggleModal: () => {
        handleClose();
      },
      showUnsupportedBrowser: () => {
        showUnsupportedBrowser();
      },
    }));

    function handleClose() {
      if (state.close) {
        state.close();
      }
      resetModal();
    }

    function showTimeoutModal() {
      const modal = {
        title: "Session Timeout",
        body: <text>Your session has timed out, please log in again</text>,
        primaryButton: null,
        secondaryButton: (
          <Button color="secondary" tag="a" onClick={() => logoutUser()}>
            Return to Login
          </Button>
        ),
        modalSize: "md",
        displayToggleCloseButton: false,
        displayed: true,
      } as IModalProps;

      setState(modal);
    }

    function showUnsupportedBrowser() {
      const modal = {
        title: "",
        body: (
          <div className="unsupported-browser-body">
            <h3>Unsupported Browser</h3>
            <div className="unsupported-browser-text-body">
              You are currently using a browser that is not supported by CData
              Connect Cloud. Download one of the browsers listed below to get
              the most out of your experience.
            </div>
            <div>
              <Row>
                <Col>
                  <Edge aria-description="Microsoft Edge logo" />
                  <div>Microsoft Edge</div>
                </Col>
                <Col>
                  <Safari aria-description="Apple Safari logo" />
                  <div>Apple Safari</div>
                </Col>
                <Col>
                  <Chrome aria-description="Google Chrome logo" />
                  <div>Google Chrome</div>
                </Col>
                <Col>
                  <Firefox aria-description="Mozilla Firefox logo" />
                  <div>Mozilla Firefox</div>
                </Col>
              </Row>
            </div>
          </div>
        ),
        modalSize: "md",
        displayed: true,
        removeBorders: true,
      } as IModalProps;

      setState(modal);
    }

    const footerClasses = classNames({
      "border-0": state.removeBorders,
      "spaced-footer": state.spacedFooter,
    });

    const bodyClasses = classNames({
      "pt-0 pb-0": state.removeYPaddingOnBody,
    });

    return (
      <Modal
        ref={ref as any}
        centered
        isOpen={state.displayed}
        size={state.modalSize}
        toggle={state.displayToggleCloseButton ? handleClose : undefined}
        className={`p-0 ${getComponentName()} ${state.className}`}
        backdrop="static"
      >
        <ModalHeader
          className={state.removeBorders ? "border-0" : ""}
          toggle={state.displayToggleCloseButton ? handleClose : undefined}
        >
          {state.title}
        </ModalHeader>
        <ModalBody className={bodyClasses}>{state.body}</ModalBody>
        <ModalFooter
          hidden={!state.primaryButton && !state.secondaryButton}
          className={footerClasses}
        >
          {state.secondaryButton}
          {state.primaryButton}
        </ModalFooter>
      </Modal>
    );
  },
);

CDataModal.displayName = "CDataModal";
