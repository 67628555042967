import {
  addAccountInfo,
  addCacheConnection,
  addDriversList,
  addSubscription,
  addUsage,
} from "../redux/actions";
import { useFeatureFlags } from "./useFeatureFlags";
import { useNavigate } from "react-router-dom";
import chameleon from "@chamaeleonidae/chmln";
import { IUser } from "../models/Users/IUser";
import { ISubscriptionInfo } from "../models/Billing/ISubscriptionInfo";
import { FeatureId } from "../models/Features/FeatureId";
import { UserRole } from "../models/Users/UserRole";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IAccount, IBillingUsage, IConnection, IDriverList } from "../models";
import { differenceInMonths, parseISO } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import { getUserSession } from "src/api/Users/getUserSession";
import { HttpError } from "src/api/cdataFetch";

function registerUserWithChameleon(
  user: IUser | null | undefined,
  subscription: ISubscriptionInfo,
  account: IAccount | null | undefined,
) {
  // If we haven't initialized chameleon (e.x. in local dev)
  // then just bail out.
  if (window.chmln == null) {
    return;
  }

  if (user != null) {
    const isConnectSpreadsheet =
      subscription?.limits?.availableFeatureIds.includes(
        FeatureId.ConnectForSpreadsheets,
      );

    const isCDataUser =
      (subscription?.plan?.id === 1 ||
        user?.email?.includes("@cdata.com") ||
        account?.contactEmail?.includes("@cdata.com")) ??
      false;
    try {
      chameleon.identify(user.id, {
        accountId: user.accountId,
        // True if the user is an OEM user, either an OEM admin or a regular OEM user.
        isOem:
          user.role === UserRole.OEMAdmin || user.role === UserRole.ServiceUser,
        isConnectSpreadsheet: isConnectSpreadsheet,
        role: user.role != null ? UserRole[user.role] : undefined,
        isCdataUser: isCDataUser,
        is3MonthsAfterFirstLogin:
          differenceInMonths(new Date(), parseISO(user.created)) >= 3,
      });
    } catch (err) {
      // Do not blow the app up if chameleon doesn't work.
      console.error("An error occurred registering a user with chameleon");
      console.error(err);
    }
  }
}

export function useSessionState() {
  const dispatch = useAppDispatch();
  const featureFlags = useFeatureFlags();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const drivers = useAppSelector((state) => state.driversList?.drivers);

  const { mutateAsync: getUserSessionInfoAsync } = useMutation({
    mutationKey: ["/users/session"],
    mutationFn: async () => {
      const payload = await getUserSession();

      // This code would normally be in an onSuccess callback but the calling code
      // expects everything to happen synchronously.
      // If this is moved to onSuccess the setup wizard blows up.
      featureFlags.setTrait(
        "company",
        (payload.accountInfo as IAccount).organization,
      );

      dispatch(addSubscription(payload.subscriptionInfo as ISubscriptionInfo));
      dispatch(addDriversList(payload.driverList as IDriverList));
      dispatch(addUsage(payload.billingUsage as IBillingUsage));
      dispatch(addAccountInfo(payload.accountInfo as IAccount));
      dispatch(addCacheConnection(payload.cacheConnection as IConnection));

      return payload;
    },
    meta: {
      errorMessage: "Failed to get session info due to the following error:",
    },
    onSuccess: (payload) => {
      registerUserWithChameleon(
        user,
        payload.subscriptionInfo as ISubscriptionInfo,
        payload.accountInfo as IAccount,
      );
    },
    onError: (error) => {
      const isOemUser = window.location.pathname.startsWith("/oem/user/");
      const httpStatus: number | null =
        error instanceof HttpError ? error.statusCode : null;

      // We cannot send OEM users to login or sign-up pages, they get short lived access tokens.
      if (!isOemUser) {
        if (httpStatus === 401) {
          window.location.href = encodeURI(
            window.location.origin + "/account/login",
          );
        } else {
          if (error && error.message && error.message.includes("signing up")) {
            const redirectUri = "/auth/user-sign-up";
            window.location.href = encodeURI(
              window.location.origin + redirectUri,
            );
          } else {
            navigate("/auth/500");
          }
        }
      } else {
        navigate("/auth/500");
      }
    },
  });

  async function initializeSessionState(skipIfAlreadyLoaded = false) {
    // Just see if drivers have already loaded and skip loading again.
    // Using drivers here just because drivers are only set in this function and no where else.
    if (skipIfAlreadyLoaded === true && drivers != null) {
      return;
    }
    await getUserSessionInfoAsync();
  }

  return {
    initializeSessionState,
  };
}
