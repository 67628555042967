export enum SidebarCategories {
  Overview = "Overview",
  Data = "Data",
  Manage = "Manage",
  Documentation = "Documentation",
}

export enum SidebarOption {
  Dashboard = "Dashboard",
  Sources = "Sources",
  Consumers = "Consumers",
  Workspaces = "Workspaces",
  Explorer = "Explorer",
  Jobs = "Jobs",
  Users = "Users",
  OData = "OData",
  Logs = "Logs",
  Api = "API",
  SupportImpersonation = "Support Impersonation",
}
