"use client";
import { Suspense, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserRole } from "../models";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import { Footer } from "../components/Footer";
import Loader from "../components/Loader";

import { RequestType } from "../components/withAPI";
import "../assets/scss/_app.scss";
import { SUPPORTED_BROWSERS } from "../components/notification/DashboardNotificationManagerConstants";
import { isMacSafariWebView } from "../components/notification/NotificationBarUtil";
import { browserName } from "react-device-detect";
import { ModalContext } from "../routes/ModalContext";
import { useSessionState } from "../hooks/useSessionState";
import { tosLastUpdated } from "../utility/TermsOfServiceLastUpdated";
import { Button } from "reactstrap";
import { IModalProps } from "../components/CDataModal";
import { useAPI } from "../components/useAPI";
import { logoutUser } from "../routes/AuthorizeContext";
import { ErrorBoundary } from "react-error-boundary";
import {
  GenericErrorPage,
  logBoundaryErrorToDatadog,
} from "../pages/auth/GenericErrorPage";
import { DashboardNotificationManager } from "../components/notification/DashboardNotificationManager";
import { useAppSelector } from "../redux/hooks";
import { store } from "../redux/store";

export interface IModalFunctions {
  showErrorModal: (text: string, error: any) => void;
  showTimeoutModal: () => void;
  setModal: (modal: IModalProps) => void;
  toggleModal: () => void;
  showUnsupportedBrowser?: () => void;
}

export const Dashboard = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const user = useAppSelector((s) => s.user);
  const isOemAdmin = user?.role === UserRole.OEMAdmin;
  const [loading, setLoading] = useState<boolean>(true);

  const modalContext = useContext(ModalContext);
  const sessionState = useSessionState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await sessionState.initializeSessionState();
      if (!store.getState().accountInfo!.isSetupFinished) {
        navigate("/initial-setup#select-initial-connection");
      }

      displayInitialUnsupportedBrowser();
      displayTermsOfServiceModal();

      setLoading(false);
    };

    fetchData();
  }, []); // eslint-disable-line

  const displayInitialUnsupportedBrowser = () => {
    if (
      !SUPPORTED_BROWSERS.includes(browserName) &&
      !isMacSafariWebView(navigator.userAgent)
    ) {
      modalContext.showUnsupportedBrowser();
    }
  };

  function displayTermsOfServiceModal() {
    const { accountInfo } = store.getState();
    if (
      accountInfo?.tosVersion == null ||
      accountInfo!.tosVersion! < tosLastUpdated
    ) {
      const modal = {
        title: "Accept New Terms and Conditions",
        body: (
          <div>
            Our{" "}
            <a
              href="https://cloud.cdata.com/docs/terms-of-service.html"
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            have changed. Please accept the new terms to continue.
          </div>
        ),
        primaryButton: (
          <Button
            color="primary"
            onClick={updateTosAcceptedDate}
            data-testid="button-accept-tos"
          >
            I agree
          </Button>
        ),
        secondaryButton: (
          <Button
            color="secondary"
            tag="a"
            onClick={() => logoutUser()}
            className="logout-button"
            data-testid="button-logout"
          >
            Return to Login
          </Button>
        ),
        displayToggleCloseButton: false,
        modalSize: "md",
        displayed: true,
      } as IModalProps;
      modalContext.setModal(modal);
    }
  }

  const updateTosAcceptedDate = async () => {
    const { status } = await api.callAPI(
      RequestType.Put,
      `/account/tos/${tosLastUpdated}`,
      "Failed to update the Terms of Service acceptance date",
    );
    if (status === 200) {
      modalContext.toggleModal();
    }
  };

  const renderDashboard = () => {
    return (
      <>
        <Navbar />
        <Wrapper>
          {/* OEM admins currently do not get a sidebar, this will change if they ever have more than 1 page they can see. */}
          {!isOemAdmin && <Sidebar />}
          <Main className="">
            <ErrorBoundary
              fallbackRender={(props) => (
                <GenericErrorPage
                  error={props.error}
                  isOutsideDashboard={false}
                  resetErrorBoundary={props.resetErrorBoundary}
                />
              )}
              onError={logBoundaryErrorToDatadog}
            >
              <DashboardNotificationManager />
              <Content>
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              </Content>
              <Footer />
            </ErrorBoundary>
          </Main>
        </Wrapper>
      </>
    );
  };

  const contents = loading ? (
    <div className="text-center mt-4">
      <Loader />
    </div>
  ) : (
    renderDashboard()
  );

  return (
    <>
      <div className="p-0 layouts-dashboard">{contents}</div>
    </>
  );
};
