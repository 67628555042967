import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

interface Page500Props {
  hideReturnToWebsiteButton?: boolean;
  error?: Error | null;
}

const Page500 = (props: Page500Props) => {
  const { hideReturnToWebsiteButton, error } = props;
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">500</h1>
      <p className="h1">Internal server error.</p>

      <p className="h2 font-weight-normal mt-3 mb-4">
        {!error && (
          <>
            The server encountered something unexpected that didn&lsquo;t allow
            it to complete the request.
          </>
        )}
        {error && <>{error.message}</>}
      </p>

      {!hideReturnToWebsiteButton && (
        <Link to="/">
          <Button color="primary" size="lg">
            Return to website
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Page500;
