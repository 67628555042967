import { useEffect } from "react";

import {
  BillingStatus,
  IBillingItem,
  ISubscriptionInfo,
  UserRole,
} from "../../../models";

import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { Container } from "react-bootstrap";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { BillingUsageBars } from "./BillingUsageBars";
import { Status } from "../../../utility/Status";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addSubscription, addUsage } from "../../../redux/actions";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { NextBillingDate } from "./NextBillingDate";
import { useIsConnectForSpreadsheets } from "../../../hooks/useIsConnectForSpreadsheets";
import {
  CDataButton,
  ButtonType,
} from "../../../components/buttons/CDataButton";
import { is2025RepricingBillingPlan } from "src/utility/billing/is2025RepricingBillingPlan";
import { useQuery } from "@tanstack/react-query";
import { getBillingUsage } from "src/pages/users/api/getBillingUsage";
import { getSubscriptionInfo } from "src/pages/settings/BillingInfoTab/api/getSubscriptionInfo";
import { getBillingAggregates } from "src/pages/settings/BillingInfoTab/api/getBillingAggregates";
import { ManageBillingButton } from "src/pages/settings/BillingInfoTab/ManageBillingButton";
import Loader from "src/components/Loader";
import Page500 from "src/pages/auth/Page500";
import { BillingProvider } from "src/models/Billing/BillingProvider";
import { RepricingPlanCardBody } from "src/pages/settings/BillingInfoTab/RepricingPlanInfo/RepricingPlanCardBody";

export function StripeBillingInfoTab() {
  const isConnectForSpreadsheetsClient = useIsConnectForSpreadsheets();
  const dispatch = useAppDispatch();

  const user = useAppSelector((s) => s.user);
  const isOem = user.role === UserRole.OEMAdmin;
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  const {
    data: billingUsage,
    isLoading: isLoadingBillingUsage,
    error: billingUsageError,
  } = useQuery({
    queryKey: ["/billing/usage"],
    queryFn: getBillingUsage,
    meta: {
      errorMessage: "Failed to get account usage due to the following error:",
    },
  });

  useEffect(() => {
    if (billingUsage) {
      dispatch(addUsage(billingUsage));
    }
  }, [dispatch, billingUsage]);

  const {
    data: subscriptionInfo,
    isLoading: isLoadingSubscriptionInfo,
    error: subscriptionInfoError,
  } = useQuery({
    queryKey: ["/billing/subscription"],
    queryFn: getSubscriptionInfo,
    meta: {
      errorMessage:
        "Failed to get subscription info due to the following error:",
    },
  });

  useEffect(() => {
    if (subscriptionInfo) {
      dispatch(addSubscription(subscriptionInfo));
    }
  }, [dispatch, subscriptionInfo]);

  const {
    data: aggregateInfo,
    isLoading: isLoadingAggregateInfo,
    error: aggregateInfoError,
  } = useQuery({
    queryKey: ["/billing/aggregates"],
    queryFn: getBillingAggregates,
    meta: {
      errorMessage: "Failed to get aggregate info due to the following error:",
    },
  });

  if (billingUsageError || subscriptionInfoError || aggregateInfoError) {
    return (
      <Page500
        error={billingUsageError || subscriptionInfoError || aggregateInfoError}
      />
    );
  }

  if (
    isLoadingBillingUsage ||
    billingUsage == null ||
    isLoadingSubscriptionInfo ||
    subscriptionInfo == null ||
    isLoadingAggregateInfo ||
    aggregateInfo == null
  ) {
    return <Loader />;
  }

  const is2025RepricingPlan = is2025RepricingBillingPlan(subscriptionInfo.plan);

  const hasNoPlanInfo =
    subscriptionInfo.billingStatus === BillingStatus.NewAccount ||
    subscriptionInfo.billingStatus === BillingStatus.Canceled ||
    subscriptionInfo.plan == null;

  const showManageBillingButton =
    subscriptionInfo.billingProvider === BillingProvider.Stripe &&
    subscriptionInfo.billingStatus !== BillingStatus.NewAccount &&
    !isOem &&
    !isSupportImpersonationActive;

  let billingPlanCardBody: JSX.Element;

  if (hasNoPlanInfo) {
    billingPlanCardBody = (
      <NoBillingInfoCardBody
        subscriptionInfo={subscriptionInfo}
        showManageBillingButton={showManageBillingButton}
      />
    );
  } else if (is2025RepricingPlan) {
    billingPlanCardBody = (
      <RepricingPlanCardBody
        subscriptionInfo={subscriptionInfo}
        showManageBillingButton={showManageBillingButton}
        billingPlan={subscriptionInfo.plan!}
      />
    );
  } else {
    billingPlanCardBody = (
      <BillingPlanCardBody
        subscriptionInfo={subscriptionInfo}
        showManageBillingButton={showManageBillingButton}
        billingPlan={subscriptionInfo.plan!}
      />
    );
  }

  return (
    <Container fluid className="p-0 billingInfoTab-stripeBillingInfoTab">
      <div className="container-fluid content-row">
        <Row>
          <div className="col d-flex">
            <Card className="billing-card">{billingPlanCardBody}</Card>
          </div>
          {!isConnectForSpreadsheetsClient && (
            <Col className="col-auto">
              <UpdatePlanSalesCTA />
            </Col>
          )}
        </Row>
        {!hasNoPlanInfo && (
          <BillingUsageBars
            subscriptionInfo={subscriptionInfo}
            aggregateInfo={aggregateInfo}
            billingUsageInfo={billingUsage}
          />
        )}
      </div>
    </Container>
  );
}

type BillingPlanCardBodyProps = {
  subscriptionInfo: ISubscriptionInfo;
  showManageBillingButton: boolean;
  billingPlan: IBillingItem;
};

function BillingPlanCardBody(props: BillingPlanCardBodyProps) {
  const { subscriptionInfo, showManageBillingButton, billingPlan } = props;

  const user = useAppSelector((s) => s.user);
  const isOem = user.role === UserRole.OEMAdmin;

  const isConnectForSpreadsheetsClient = useIsConnectForSpreadsheets();

  const userLimit = subscriptionInfo.limits?.userLimit ?? 0;

  const connectionLimit = subscriptionInfo.limits?.connectionLimit ?? 0;
  const connectionsPerSource =
    subscriptionInfo.limits?.connectionsPerSource ?? 0;

  const isTrialOrTrialEnding =
    subscriptionInfo.billingStatus === BillingStatus.Trial ||
    subscriptionInfo.billingStatus === BillingStatus.TrialEnding;

  const purchasedAddons = subscriptionInfo.purchasedAddons ?? [];
  const includedAddons = subscriptionInfo.includedAddons ?? [];

  return (
    <CardBody>
      <div className="billingCardHeader">
        <div className="billingCardHeader-title">
          <CDataTypography variant="typography-variant-headline-4">
            Billing
          </CDataTypography>
        </div>
        <div className="billingCardHeader-buttonBar">
          {isConnectForSpreadsheetsClient && (
            <CDataButton
              buttonType={ButtonType.Borderless}
              data-testid="button"
              className="plan-button"
              onClick={() => {
                window.open(
                  "http://www.cdata.com/spreadsheets/pricing",
                  "_blank",
                );
              }}
            >
              <span className="align-middle me-2">Compare Plans </span>
              <i className="fa fa-regular fa-arrow-up-right-from-square align-middle"></i>
            </CDataButton>
          )}

          {showManageBillingButton && <ManageBillingButton />}
        </div>
      </div>
      <FormGroup className="mb-3">
        <h5 className="mb-3">Billing Plan</h5>
        <div id="accountName">
          {billingPlan.name}
          <Status className="ms-2" status={subscriptionInfo.billingStatus} />
        </div>
      </FormGroup>
      <NextBillingDate subscriptionInfo={subscriptionInfo} />
      <FormGroup
        hidden={
          (isConnectForSpreadsheetsClient && isTrialOrTrialEnding) || isOem
        }
        className="mb-0 row plan-contents"
      >
        <div className="col-sm-2">
          <h5 className="mb-0">Your Plan Includes</h5>
          <div>
            <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
            {(userLimit < 0 ? "Unlimited" : userLimit) +
              (userLimit !== 1 ? " User Seats" : " User Seat")}
          </div>
          {subscriptionInfo.limits?.dataSourceLimit !== -1 ? (
            <div>
              <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
              {subscriptionInfo.limits?.dataSourceLimit +
                (subscriptionInfo.limits?.dataSourceLimit !== 1
                  ? " Data Sources"
                  : " Data Source")}
              {connectionsPerSource > 0 && (
                <>
                  <i className="fa fa-solid fa-circle divider-dot align-middle mx-2" />
                  <CDataTypography
                    className="d-inline-block connections-per-source"
                    variant="typography-variant-body-regular-italic"
                  >
                    {connectionsPerSource}
                    {connectionsPerSource === 1
                      ? " Connection "
                      : " Connections "}
                    p/source
                  </CDataTypography>
                </>
              )}
            </div>
          ) : null}
          {!isConnectForSpreadsheetsClient && (
            <div>
              <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
              {connectionLimit < 0 ? "Unlimited" : connectionLimit}
              {connectionLimit !== 1 ? " Connections" : "Connection"}
            </div>
          )}
          {billingPlan.description != null
            ? billingPlan.description.split("|").map((descriptionItem) => {
                return (
                  <div key={descriptionItem}>
                    <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                    {descriptionItem}
                  </div>
                );
              })
            : null}
        </div>
        {(purchasedAddons.length > 0 || includedAddons.length > 0) && (
          <div className="col-sm-2">
            <h5 className="mb-0">Add-Ons:</h5>
            {includedAddons.map((addOn) => {
              return (
                <div key={addOn.name}>
                  <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                  {addOn.name} (Included)
                </div>
              );
            })}
            {purchasedAddons.map((addOn) => {
              return (
                <div key={addOn.name}>
                  <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                  {addOn.name}
                </div>
              );
            })}
          </div>
        )}
      </FormGroup>
    </CardBody>
  );
}

type NoBillingInfoProps = {
  subscriptionInfo: ISubscriptionInfo;
  showManageBillingButton: boolean;
};

/** Displayed when the user has no billing info or their plan is cancelled. */
function NoBillingInfoCardBody(props: NoBillingInfoProps) {
  const { subscriptionInfo, showManageBillingButton } = props;

  return (
    <CardBody>
      <div className="billingCardHeader">
        <div className="billingCardHeader-title">
          <CDataTypography variant="typography-variant-headline-4">
            Billing
          </CDataTypography>
        </div>
        <div className="billingCardHeader-buttonBar">
          {showManageBillingButton && <ManageBillingButton />}
        </div>
      </div>
      <FormGroup className="mb-0">
        <h5 className="mb-3">Billing Plan</h5>
        <div id="accountName">
          <Status className="mt-2" status={subscriptionInfo.billingStatus} />
          <div className="mt-3 mb-0">
            You currently do not have a CData Connect Cloud subscription.
            Reactivate your plan today to take advantage of all its features.
          </div>
        </div>
      </FormGroup>
    </CardBody>
  );
}

function UpdatePlanSalesCTA() {
  return (
    <Card>
      <CardBody className="sales-card-body">
        <Row>
          <h2 className="mb-0">Contact Sales</h2>
        </Row>
        <Row className="sales-prompt">
          Looking to update your Connect Cloud subscription plan? Contact our
          Sales team today to assist you in your request!
        </Row>
        <span className="phone-icon-bg">
          <i className="fa fa-phone fa-lg phone-icon" />
        </span>
        <p className="sales-contact-info mb-0">
          <b>Mon-Fri, 9am-6pm EST</b>
          <br />
          (800) 235-7250 (within US)
          <br />
          (919) 928-5214 (outside US)
        </p>
        <a
          href={`mailto:${getSalesEmailAddress()}`}
          className="btn btn-primary"
        >
          <i className="fa fa-envelope fa-sm align-middle me-2 no-pointer-event" />
          Email Sales
        </a>
      </CardBody>
    </Card>
  );
}
