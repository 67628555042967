import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "../../../components/Loader";
import {
  successMessage,
  openPersonalAccessTokenModal,
  openRegeneratePersonalAccessTokenModal,
  openDeletePersonalAccessTokenModal,
} from "./AccessTokenTabModals";
import { IModalFunctions } from "../../../layouts/Dashboard";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { IPersonalAccessToken } from "../../../models";
import { ColumnDef } from "@tanstack/react-table";
import ListTable from "../../../components/tables/ListTable";
import { orderBy } from "lodash";
import classNames from "classnames";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";

interface IAccessTokenProps extends IModalFunctions {
  toggleModal: any;
}

export const AccessTokenTab = (props: IAccessTokenProps) => {
  const api = useAPI();

  const [personalAccessTokens, setPersonalAccessTokens] = useState<
    IPersonalAccessToken[]
  >([]);
  const [consentExpirationDate, setConsentExpirationDate] = useState("");
  const [processingRequest, setProcessingRequest] = useState(false);
  const [loading, setLoading] = useState(true);

  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  useEffect(() => {
    async function initializeComponent() {
      await getPersonalAccessTokens();
      await getCurrentUserInfo();

      setLoading(false);
    }

    initializeComponent();
  }, []); // eslint-disable-line

  if (loading) {
    return <Loader />;
  }

  const columns: ColumnDef<IPersonalAccessToken>[] = [
    {
      accessorKey: "name",
      id: "name",
      enableSorting: true,
      header: "Name",
      meta: {
        className: "name-col",
      },
      cell: ({ row }) => {
        return <>{row.original.name}</>;
      },
    },
    {
      accessorKey: "creationDate",
      id: "creationDate",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const dateA = rowA.original.created ?? "";
        const dateB = rowB.original.created ?? "";
        return dateA.localeCompare(dateB);
      },
      header: "Creation Date",
      cell: ({ row }) => {
        const createdDate = formatUTCDateTime(row.original.created!) + " UTC";
        let expirationDate = row.original.expirationDate
          ? formatUTCDateTime(row.original.expirationDate)
          : null;

        if (consentExpirationDate && row.original.expirationDate) {
          const newConsentExpirationDate = new Date(consentExpirationDate);
          const tokenExpirationDate = new Date(row.original.expirationDate);

          if (newConsentExpirationDate < tokenExpirationDate) {
            expirationDate = formatUTCDateTime(consentExpirationDate);
          }
        }

        return expirationDate ? (
          <span className="d-flex flex-column">
            <span className="hide-overflow-text">{createdDate}</span>
            <span className="expiration-date hide-overflow-text">
              {"Expires: " + expirationDate + " UTC"}
            </span>
          </span>
        ) : (
          <>{createdDate}</>
        );
      },
    },
    {
      accessorKey: "rowButtons",
      enableSorting: false,
      header: "",
      meta: {
        className: "row-buttons-col",
      },
      cell: ({ row }) => {
        return (
          <div className="row-buttons-cell">
            <button
              type="button"
              onClick={() => {
                openRegeneratePersonalAccessTokenModal(
                  props.setModal,
                  props.toggleModal,
                  regeneratePersonalAccessToken,
                  row.original.id ?? "",
                );
              }}
              className="table-button"
              aria-label="regenerate token"
              data-testid="button-regenerate-pat"
            >
              <i className="fa fa-regular fa-arrows-rotate align-middle"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                openDeletePersonalAccessTokenModal(
                  props.setModal,
                  deletePersonalAccessToken,
                  props.toggleModal,
                  row.original.id ?? "",
                );
              }}
              className="table-button"
              aria-label="delete token"
              data-testid="button-delete-pat"
            >
              <i className="fa-regular fa-xmark fa-lg align-middle"></i>
            </button>
          </div>
        );
      },
    },
  ];

  async function getPersonalAccessTokens() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/pat/list",
      "Failed to get personal access token list due to the following error:",
    );
    if (status === 200) {
      setPersonalAccessTokens(payload.personalAccessTokens);
    }
  }

  async function getCurrentUserInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/users/self",
      "Failed to get current user info due to the following error.",
    );
    if (status === 200) {
      setConsentExpirationDate(payload.consentExpirationDate ?? "");
    }
  }

  async function createPersonalAccessToken(event: React.FormEvent) {
    event.preventDefault();
    setProcessingRequest(true);

    const formData = new FormData(event.target as HTMLFormElement);
    const values = Object.fromEntries(formData.entries());

    if (isSupportImpersonationActive) {
      values.name = "CData Support";
      values.expirationDate = new Date(
        new Date().getTime() + 24 * 60 * 60 * 1000,
      ).toISOString();
    } else if (values.name === "CData Support") {
      ToastrError(
        "Failed to create personal access token due to the following error:",
        "PAT name is reserved.",
      );

      setProcessingRequest(false);
      return;
    }

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/pat/create",
      "Failed to create personal access token due to the following error:",
      values,
    );

    if (status === 200) {
      const data = payload;
      let newPersonalAccessTokens = [...personalAccessTokens];
      personalAccessTokens.push(data);
      newPersonalAccessTokens = orderBy(personalAccessTokens, (p) => p.name);

      successMessage(data.tokenString, props.toggleModal, props.setModal, true);

      setPersonalAccessTokens(newPersonalAccessTokens);
    }

    setProcessingRequest(false);
  }

  async function regeneratePersonalAccessToken(tokenId: string) {
    setProcessingRequest(true);

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/pat/regenerate/${tokenId}`,
      "Failed to regenerate personal access token due to the following error:",
    );
    if (status === 200) {
      successMessage(
        payload.tokenString,
        props.toggleModal,
        props.setModal,
        false,
      );
    }

    setProcessingRequest(false);
  }

  async function deletePersonalAccessToken(tokenId: string) {
    setProcessingRequest(true);

    const personalAccessToken = personalAccessTokens.find((x) => {
      return x.id === tokenId;
    });

    const { status } = await api.callAPI(
      RequestType.Delete,
      `/pat/delete/${tokenId}`,
      "Delete failed",
    );

    if (status === 200) {
      const newPersonalAccessTokens = personalAccessTokens.filter((x) => {
        return x.id !== tokenId;
      });
      ToastrSuccess(
        "Personal Access Token successfully deleted",
        `Your personal access token '${personalAccessToken?.name}' was successfully deleted.`,
      );

      setPersonalAccessTokens(newPersonalAccessTokens);
    }

    props.toggleModal();
    setProcessingRequest(false);
  }

  const isDisabled: boolean =
    isSupportImpersonationActive &&
    personalAccessTokens.some((x) => x.name === "CData Support");

  return (
    <Container
      fluid
      className="pages-settings-AccessTokenTab-AccessTokenTab p-0"
    >
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="mb-4">Personal Access Tokens</h4>
            </Col>
            <Col className="d-flex justify-content-end">
              <span
                id="createPATButtonWrapper"
                className="d-inline-block"
                style={{ cursor: isDisabled ? "" : "pointer" }}
              >
                <Button
                  type="button"
                  color="primary"
                  id="createPATButton"
                  className="card-actions float-end"
                  onClick={() =>
                    openPersonalAccessTokenModal(
                      props.setModal,
                      createPersonalAccessToken,
                      props.toggleModal,
                    )
                  }
                  data-testid="button-open-pat-modal"
                  disabled={isDisabled}
                  style={{ pointerEvents: isDisabled ? "none" : "auto" }}
                >
                  <i className="fa fa-plus small-icon align-middle no-pointer-event" />
                  Create PAT
                </Button>
              </span>

              {isDisabled && (
                <UncontrolledTooltip
                  placement="top"
                  target="createPATButtonWrapper"
                  trigger="hover"
                >
                  Support users can only have one active PAT at a time.
                </UncontrolledTooltip>
              )}
            </Col>
          </Row>
          <ListTable
            className={classNames("personal-access-token-table", {
              "empty-table": personalAccessTokens.length === 0,
            })}
            columns={columns}
            defaultSort={[
              {
                desc: false,
                id: "name",
              },
            ]}
            data={personalAccessTokens}
            emptyTableMessage={
              "Click on Create PAT to generate personal access tokens for your current user"
            }
            enableFiltering={false}
            enablePagination={true}
            enableCheckboxes={false}
          />
        </CardBody>
      </Card>
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </Container>
  );
};
