import { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Form, ListGroup } from "react-bootstrap";
import { IUsage, IUsageAggregate, UsageType } from "./IUsage";
import { UsageBar } from "./UsageBar";
import { formatUTCDateTime } from "../../utility/FormatUTCDateTime";
import "./Usage.scss";

export const Usage = (props: IUsage) => {
  function getComponentName() {
    return "components-Usage";
  }
  const [selectedPeriod, setSelectedPeriod] = useState<IUsageAggregate>(
    props.aggregates[0],
  );
  const usageType = Object.values(UsageType)[props.usageType] + " Usage";

  function dateDropdownSelected(event: any) {
    const selectedPeriod = props.aggregates.find(
      (x) => x.periodStart === event.target.value,
    )!;
    setSelectedPeriod(selectedPeriod);
  }

  useEffect(() => {
    setSelectedPeriod(props?.aggregates[0]);
  }, [props.aggregates]);

  if (!selectedPeriod) return <div />;

  return (
    <div className={`p-0 ${getComponentName()}`}>
      <Row className="mb-2">
        <Col className="col-sm-6">
          <h4 className="usage-title">{usageType}</h4>
        </Col>
        <Col className="float-end col-sm-6">
          {props.aggregates.length > 1 && (
            <>
              <Form.Control
                name=""
                as="select"
                className="form-select float-end"
                onChange={dateDropdownSelected}
                data-testid="select-usage-period"
              >
                {props.aggregates?.map((period) => (
                  <option key={period.periodStart} value={period.periodStart}>
                    {`${formatUTCDateTime(period.periodStart!, "MM/dd/yyyy")} - ${formatUTCDateTime(period.periodEnd!, "MM/dd/yyyy")}`}
                  </option>
                ))}
              </Form.Control>
              <h5 className="float-end px-3">Billing Period:</h5>
            </>
          )}
        </Col>
      </Row>
      <ListGroup variant="flush">
        <ListGroup.Item className="no-margin mb-3">
          <UsageBar
            aggregate={selectedPeriod}
            usageType={props.usageType}
            billingStatus={props.billingStatus}
            bottomDivider={props.bottomDivider ?? false}
          />
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};
