import { CDataTypography, TypographyColor } from "../text/CDataTypography";
import { ButtonType, CDataButton } from "../buttons/CDataButton";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./NotificationBar.scss";

type NotificationBarColor =
  | "notification-bar-blue"
  | "notification-bar-orange"
  | "notification-bar-pale-orange"
  | "notification-bar-red"
  | "notification-bar-light-blue";

export enum IconOverrideOptions {
  Circle = "fa-solid fa-circle-info fa-xl",
  Triangle = "fa-solid fa-triangle-exclamation fa-xl",
  Bulb = "fa-solid fa-lightbulb fa-xl",
}

export interface INotificationBar {
  /** The background and icon color for the bar. */
  barColor: NotificationBarColor;
  /** Limits the maximum number of lines displayed to two. */
  hideTextOverflow?: boolean;
  /** If provided, the name of the font-awesome-icon to display in the bar. Each notification type has a default icon, but this can be used to override it. */
  iconOverride?: string;
  /** By default, the link will navigate to navigateDestination. If this is provided, it overrides this behavior to execute a different function. */
  linkClickOverride?: () => void;
  /** If provided, a borderless button renders at the end of the banner with this text. */
  linkText?: string;
  /** The text contents of the bar. Can be provided as a bare string or as a JSX element, e.g. to embed an email link. */
  message: string | JSX.Element;
  /** The destination to navigate to when clicking the link in the banner. */
  navigateDestination?: string;
  /** If supplied, the banner will display a clickable X that, when clicked, executes the function passed in here. */
  onClose?: () => void;
}

export const NotificationBar = (props: INotificationBar) => {
  const {
    barColor,
    linkClickOverride,
    hideTextOverflow,
    iconOverride,
    linkText,
    message,
    navigateDestination,
    onClose,
  } = props;

  const navigate = useNavigate();

  let textColor: TypographyColor = "typography-color-white";

  if (barColor === "notification-bar-pale-orange") {
    textColor = "typography-color-burnt-orange";
  } else if (barColor === "notification-bar-light-blue") {
    textColor = "typography-color-dark-blue";
  }

  // Set the font-awesome-icon class based first on overrides, then on default icons by color
  let iconName: string;
  if (iconOverride) {
    iconName = iconOverride;
  } else if (barColor === "notification-bar-blue") {
    iconName = "fa-solid fa-circle-info fa-xl";
  } else {
    iconName = "fa-solid fa-triangle-exclamation fa-xl";
  }

  // Wrap plain strings in a CDataTypography component, and pass elements in unaltered.
  let messageContents: string | JSX.Element;
  if (typeof message === "string") {
    messageContents = (
      <CDataTypography color={textColor}>{message}</CDataTypography>
    );
  } else {
    messageContents = message;
  }

  // The default link action is navigate, which saves us from passing it in. If an override is passed, set it here.
  let linkAction: () => void;
  if (linkText) {
    if (linkClickOverride) {
      linkAction = linkClickOverride;
    } else if (navigateDestination) {
      linkAction = () => navigate(navigateDestination);
    } else {
      throw new Error(
        "When linkText is supplied, either linkClickOverride or navigateDestination must be set in the props.",
      );
    }
  }

  return (
    <div className={`components-notification-bar ${barColor}`}>
      <Row>
        <Col className="notification-bar-icon pe-0">
          <i className={`${iconName}`} />
        </Col>
        <Col
          className={hideTextOverflow ? "notification-bar-hide-overflow" : ""}
        >
          {messageContents}
        </Col>
        {linkText && (
          <Col className="notification-bar-link pe-0">
            <CDataButton
              buttonType={ButtonType.NotificationBar}
              className="py-0"
              disableRipple
              onClick={() => linkAction()}
            >
              <CDataTypography
                color={textColor}
                variant="typography-variant-underline"
              >
                {linkText}
              </CDataTypography>
            </CDataButton>
          </Col>
        )}
        {onClose && (
          <Col className="notification-bar-close pe-1 ps-0">
            <CDataButton
              buttonType={ButtonType.NotificationBar}
              className="ps-0 py-0"
              disableRipple
              onClick={onClose}
            >
              <i className="fa-regular fa-xmark me-1 close-button" />
            </CDataButton>
          </Col>
        )}
      </Row>
    </div>
  );
};
